ï»¿/* eslint-disable no-invalid-this */
const $ = require("jquery");
const ko = require("knockout");
const hotkeys = require("../Navigation/plex-hotkeys");
const jsUtils = require("../Utilities/plex-utils-js");
require("../Mixins/plex-mixins-disposable"); // eslint-disable-line import/no-unassigned-import

const SEARCH_INPUT_SELECTOR = ".plex-grid-search-input input";
const SEARCH_ROW_CSS = "plex-grid-search-row";
const PREFETCH_COUNT = 50;
const searchers = [];
let activeSearch = null;
let activeSearchIndex = -1;

hotkeys.addHotKey("ctrl+f", { bindGlobal: true }, () => {
  if (searchers.length === 0) {
    return true;
  }

  // cycle through grids in viewport when user hits ctrl+f
  // enable native search once all have been cycled through.
  const currentSearch = ko.utils.arrayFirst(searchers, (s, index) => {
    const validSearcher =
      s.inViewport() &&
      s.grid.controller.results().length > 30 &&
      s.grid.controller.config.columns.some((col) => col.isSearchable) &&
      index > activeSearchIndex;
    if (validSearcher) {
      activeSearchIndex = index;
      return true;
    }
    return false;
  });

  if (activeSearch && currentSearch !== activeSearch) {
    activeSearch.enabled(false);
  }

  activeSearch = currentSearch;
  if (activeSearch) {
    activeSearch.enabled(true);
    return !activeSearch.enabled();
  }

  activeSearchIndex = -1;
  return true;
});

function GridSearch(grid) {
  this.init(grid);
}

GridSearch.prototype = {
  constructor: GridSearch,

  init: function (grid) {
    this.grid = grid;
    this.searchText = ko.observable("");
    this.enabled = grid.controller.searchEnabled;
    this.currentIndex = ko.observable(-1);
    this.results = ko.observableArray();
    this.done = ko.observable(true);
    this.iterator = ko.observable();

    // reset search when the text changes
    this.addDisposable(this.searchText.subscribe(this.reset, this));

    this.addDisposable(
      this.enabled.subscribe((enabled) => {
        this.reset();
        if (enabled) {
          // need to defer because textbox will not be visible initially
          jsUtils.defer(() => this._setFocus(true));
        }
      })
    );

    if (grid.options.fixedHeader) {
      // when the header switches between fixed mode the search
      // input will switch so that focus/selection is maintained
      grid.$thead.on("fixed.plex", function () {
        if ($(document.activeElement).is(SEARCH_INPUT_SELECTOR)) {
          const selectionStart = document.activeElement.selectionStart;
          const selectionEnd = document.activeElement.selectionEnd;

          // ensure search box within the fixed header has been made visible
          // before below selection, else search box within the table gets focus
          // causing a unwanted scroll.
          Promise.resolve().then(() => {
            $(this).find(SEARCH_INPUT_SELECTOR).selectRange(selectionStart, selectionEnd);
          });
        }
      });

      grid.$thead.on("unfixed.plex", () => {
        if ($(document.activeElement).is(SEARCH_INPUT_SELECTOR)) {
          const selectionStart = document.activeElement.selectionStart;
          const selectionEnd = document.activeElement.selectionEnd;

          grid.$table.find(SEARCH_INPUT_SELECTOR).selectRange(selectionStart, selectionEnd);
        }
      });
    }

    this.$resizeParent = this.grid.$element.closest(".ui-resizable");
    if (this.$resizeParent.length === 0) {
      this.$resizeParent = $(window);
    }

    this.$resizeParent.on("resizeCompleted-x", this.handleResize.bind(this));
    this.handleResize();

    this.status = ko.pureComputed(() => {
      if (!this.iterator()) {
        return "";
      }

      let count = this.results().length;
      const done = this.done();
      const index = this.currentIndex();

      if (!done) {
        count += "+";
      }

      return { text: "{1} of {2}", tokens: [index + 1, count] };
    });

    this.addDisposable(this.status);
    this._applyBindings();

    searchers.push(this);

    // sort by searchers by their position
    searchers.sort((a, b) => a.grid.$element.offset().top - b.grid.$element.offset().top);
  },

  _applyBindings: function () {
    // because the header is cloned to allow for fixed headers, we need to apply the bindings manually
    const self = this;
    const $sections = this.grid.$element.find(".plex-grid-search-section");

    $sections.each(function () {
      $(this)
        .closest("tr")
        .each(function () {
          ko.applyBindingsToNode(this, {
            visible: self.enabled
          });
        });
    });

    const atEnd = ko.pureComputed(() => !this.hasMore());

    $sections.find(".plex-grid-search-next").each(function () {
      ko.applyBindingsToNode(this, {
        click: self._handleSearchNext.bind(self),
        css: { disabled: atEnd }
      });
    });

    const atStart = ko.pureComputed(() => !this.hasLess());

    this.addDisposable(atEnd, atStart);
    $sections.find(".plex-grid-search-prev").each(function () {
      ko.applyBindingsToNode(this, {
        click: self._handleSearchPrev.bind(self),
        css: { disabled: atStart }
      });
    });

    $sections.find(".plex-grid-search-status").each(function () {
      ko.applyBindingsToNode(this, {
        glossarizedText: self.status
      });
    });

    $sections.find(SEARCH_INPUT_SELECTOR).each(function () {
      ko.applyBindingsToNode(this, {
        textInput: self.searchText,
        enterKey: self._handleSearchNext.bind(self)
      });
    });

    $sections.find(".plex-search-button").each(function () {
      ko.applyBindingsToNode(this, {
        click: self._handleSearch.bind(self)
      });
    });
  },

  _setFocus: function (selectText) {
    // if the fixed header is visible, use that textbox - otherwise use the normal header's textbox
    let $searchbox = this.grid.$element.find(".plex-grid-header-fixed " + SEARCH_INPUT_SELECTOR + ":first");
    if ($searchbox.length === 0) {
      $searchbox = this.grid.$table.find(SEARCH_INPUT_SELECTOR + ":first");
    }

    if ($searchbox.isOnScreen()) {
      $searchbox.focus();
      if (selectText) {
        $searchbox.select();
      }
    }
  },

  _handleSearch: function () {
    const self = this;
    const searchText = this.searchText().trim();
    if (!searchText) {
      return;
    }

    this.reset();
    const iterator = this.grid.controller.getSearchIterator(searchText);

    // prefetch
    const results = [];
    let done = false;
    while (results.length < PREFETCH_COUNT) {
      const result = iterator.next();
      if (result.done) {
        done = true;
        break;
      }

      results.push(result.value);
    }

    this.iterator(iterator);
    this.results.pushAll(results);
    this.done(done);

    // highlight all found rows
    results.forEach((r) => {
      self.grid.getCells(r.index, r.groupInfo).addClass(SEARCH_ROW_CSS);
    });

    this._handleSearchNext();
  },

  _handleSearchNext: function () {
    if (!this.iterator()) {
      // if search has not yet been invoked go ahead and let next button trigger search
      this._handleSearch();
      return;
    }

    this._handleResult(this.next());
  },

  _handleSearchPrev: function () {
    if (!this.iterator()) {
      return;
    }

    this._handleResult(this.prev());
  },

  _handleResult: function (result) {
    if (!result) {
      this._setFocus();
      return;
    }

    this.grid.scrollTo(
      result.index,
      ($row) => {
        this._setFocus();
        $row.addClass(SEARCH_ROW_CSS);
      },
      false,
      result.groupInfo
    );
  },

  handleResize: function () {
    if (!this.grid._inForm) {
      const width = Math.min(this.$resizeParent.width(), this.grid.$element.width());
      this.grid.$element.find(".plex-grid-search-container").width(width);
    }
  },

  inViewport: function () {
    return this.grid.$element.isOnScreen();
  },

  hasLess: function () {
    if (this.results().length === 0) {
      return false;
    }

    return this.currentIndex() > 0;
  },

  hasMore: function () {
    if (this.results().length === 0) {
      return false;
    }

    if (this.iterator() != null && !this.done()) {
      return true;
    }

    return this.currentIndex() < this.results().length - 1;
  },

  prev: function () {
    const index = this.currentIndex() - 1;
    if (index in this.results()) {
      this.currentIndex(index);
      return this.results()[index];
    }

    return null;
  },

  next: function () {
    const index = this.currentIndex() + 1;
    if (index in this.results()) {
      this.currentIndex(index);
      return this.results()[index];
    }

    if (!this.done()) {
      const result = this.iterator().next();
      this.done(result.done);

      if (!result.done) {
        this.currentIndex(index);
        this.results.push(result.value);
        return result.value;
      }
    }

    return null;
  },

  reset: function () {
    this.grid.$tbody.find(`.${SEARCH_ROW_CSS}`).removeClass(SEARCH_ROW_CSS);

    this.iterator(null);
    this.done(false);
    this.results.removeAll();
    this.currentIndex(-1);
  },

  dispose: function () {
    this._base.apply(this, arguments);
    searchers.remove(this);
  }
};

GridSearch.getActiveSearch = function () {
  return activeSearch;
};

jsUtils.mixin(GridSearch, "disposable");
module.exports = GridSearch;
