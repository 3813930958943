ï»¿const ko = require("knockout");
const jsUtils = require("../../Utilities/plex-utils-js");
const plexExport = require("../../../global-export");

const sizes = {
  none: 0,
  contain: 1,
  cover: 2
};

const DocumentGalleryItem = function () {
  // constructor
};

DocumentGalleryItem.prototype = {
  constructor: DocumentGalleryItem,

  onPreInit: function () {
    // this should be overridden by inheritors
  },

  init: function (config) {
    const self = this;
    self.config = config;

    self.onPreInit();

    self.imageUrl = ko.observable(self.config.imageUrl);
    self.description = ko.observable(self.config.description);
    self.backgroundColor = ko.observable(self.config.backgroundColor);

    self.displayExpand = ko.observable(false);
    self.displayDownload = ko.observable(true);
    self.isPdf = ko.observable(false);

    self.isEnvision = ko.observable(false);
    self.canvasWorkspace = ko.observable(self.config.canvasWorkspace);

    self.backgroundImage = ko.computed(() => {
      const url = self.imageUrl();
      return !!self.config.sizing && url ? "url('" + url + "')" : "";
    });

    self.backgroundSize = ko.computed(() => {
      const sizing = self.config.sizing;
      if (!sizing || sizing === sizes.none) {
        return "";
      }

      return sizing === sizes.cover ? "cover" : "contain";
    });

    self.height = ko.computed(() => {
      const height = self.config.height;
      if (!height) {
        return "";
      }

      return isNaN(height) ? height : height + "px";
    });

    self.width = ko.computed(() => {
      const width = self.config.width;
      if (!width) {
        return "";
      }

      return isNaN(width) ? width : width + "px";
    });

    this.onInit();
  },

  onInit: function () {
    // this should be overridden by inheritors
  },

  download: function () {
    return this.onDownload.apply(this, arguments);
  },

  onDownload: function () {
    // this should be overridden by inheritors
  },

  getXml: function () {
    // this should be overridden by inheritors
  }
};

jsUtils.makeExtendable(DocumentGalleryItem);

module.exports = DocumentGalleryItem;
plexExport("documentGallery.DocumentGalleryItem", DocumentGalleryItem);
