/* eslint-disable no-invalid-this */
// todo: - i want to pull out some of the data manipulation into
// data specific code - probably the datasource class

const $ = require("jquery");
const ko = require("knockout");
const ResizeObserver = require("ResizeObserver");
const actionHandler = require("../Controls/plex-handler-action");
const domUtils = require("../Utilities/plex-utils-dom");
const jsUtils = require("../Utilities/plex-utils-js");
const htmlUtils = require("../Utilities/plex-utils-html");
const gridSelectionMode = require("./plex-grid-selectionmode");
const WriterFactory = require("./Writers/plex-grid-writer-factory");
const GridSearch = require("./plex-grid-search");
const onReady = require("../Controls/plex-handler-page").onReady;
const plexImport = require("../../global-import");
const pageHandler = require("../Controls/plex-handler-page");
const browser = require("../Core/plex-browser");
const { GroupRowTypes } = require("./plex-grid-group-search");

require("../Plugins/plex-jquery"); // eslint-disable-line import/no-unassigned-import
require("../Plugins/jq-zindex"); // eslint-disable-line import/no-unassigned-import

const validatableControlSelector = "input, select, textarea";

const defaultOptions = {
  deferredBatchSize: 100,
  resizable: true,
  fixedHeader: true,
  emptyRecordCount: 10,
  emptyRecordCountInForm: 3,
  resizerWidth: 6,
  selectionMode: gridSelectionMode.none
};

const SELECTED_ROW_CLASS = "plex-grid-row-selected";
const COLLAPSED_ROW_CLASS = "plex-grid-row-collapsed";
const FIXED_HEADER_CLASS = "plex-grid-header-fixed";
const INPUT_MATCHER = /^(?:a|input|select|textarea|button|option)$/i;
const MIN_COLUMN_SIZE = 24;

// #region Helpers

function emptyElement(element) {
  while (element.firstChild) {
    ko.removeNode(element.firstChild);
  }
}

function restoreSavedColumnWidth(state, controller, offset) {
  const widths = [];
  const visibleColumns = controller.columns.filter((c) => c.visible());

  for (let i = 0; i < offset; i++) {
    const columnState = ko.utils.arrayFirst(state.columnsWidth, (c) => c.colIndex === i);
    if (columnState) {
      widths.push(columnState.width);
    }
  }

  for (let i = 0; i < visibleColumns.length; i++) {
    const columnId = controller.getStateColumnId(visibleColumns[i]);
    const columnState = ko.utils.arrayFirst(state.columnsWidth, (c) => c.columnId === columnId);
    if (columnState) {
      widths.push(columnState.width);
    }
  }

  return widths;
}

// #endregion

const Grid = function (el, options, data) {
  this.$element = $(el);
  this.columns = options.columns;
  this._columnReset = ko.getObservable(options, "columns");
  this.groups = options.groups || [];
  this.options = $.extend({}, defaultOptions, options);

  this._disposables = [];
  this.isRendering = ko.observable(false).extend({ notify: "always" });
  this.cancelPending = ko.observable(false).extend({ notify: "always" });
  this.controller = options.controller;

  data = data || options.data || [];

  // make sure data is wrapped in an observable
  this.data = ko.isObservable(data) ? data : ko.observableArray(data);
  this.init();
};

Grid.prototype = {
  constructor: Grid,

  init: function () {
    const self = this;
    if (this._columnReset) {
      self._disposables.push(
        this._columnReset.subscribe((columns) => {
          self.columns = columns;
          self.resetLayout();
        })
      );
    }

    // save elements for easy lookup
    self.$wrapper = self.$element.find(".plex-grid-wrapper");
    self.$thead = self.$element.find(".plex-grid-header-wrapper");
    self.$headerTable = self.$thead.find("table");
    self.$table = self.$element.find(".plex-grid-wrapper table");
    self.$tbody = self.$table.find("tbody");
    self.$tfoot = self.$table.find("tfoot");
    self.$scrollParent = self.$element.scrollParent();
    self._inModal = domUtils.isInDialog(self.$element);
    self._inPicker = domUtils.isInPicker(self.$element);
    self._inForm = domUtils.isInForm(self.$element);
    self._hasHeader = self.$thead.find("thead:visible").length > 0;
    self.$checkAll = self.$thead.find("input[type='checkbox']:first");
    self.writer = WriterFactory.create(self.options.writerProvider, self);
    self._initEvents();

    self._disposables.push(
      self.data.subscribe(
        (changes) => {
          // if the dom can be synced just reindex the data
          // otherwise we will re-render the entire recordset
          if (self.writer.sync(self.getData(), changes)) {
            self._indexData();
          } else {
            self.onDataChange(self.data.peek());
          }
        },
        null,
        "arrayChange"
      )
    );

    if (self.options.ranking) {
      self._initRanking();
    }

    // PATCH for Chrome - listen to pageshow event before render
    // for addressing an autocomplete issue which would fill incorrect
    // data on controls without syncing internal grid data (TRI-3822).
    if (browser.needsAutocompleteFix && !browser.hasPageShowEventFired()) {
      window.addEventListener(
        "pageshow",
        () => {
          // clear out elementCollection from columns before re-render
          self.columns.forEach((col) => delete col.elementCollection);
          self.onDataChange(self.data());
        },
        { once: true }
      );
    }

    // go ahead and render grid with data we have
    self.onDataChange(self.data());

    self.$element.on(
      "text-change",
      jsUtils.debounce(() => {
        // if the columns are locked text changes should not trigger a resize
        if (!self.hasLockedColumns) {
          self.resize();
        }
      }, 200)
    );
  },

  _initEvents: function () {
    const self = this;

    // handle cell actions
    this.$table.on("click", "tbody a.plex-grid-link", (e) => {
      let $el = $(e.target);

      // find the anchor
      if ($el[0].nodeName !== "a") {
        $el = $el.closest("a");
      }

      const colIndex = $el.data("col-index");

      const targetRow =
        $.grep($el.parents("tr"), (row) => {
          return $(row).closest("table")[0] === self.$table[0];
        }) || $el.closest("tr");

      const index = parseInt($(targetRow).attr("data-index"), 10);
      const column = self.columns[colIndex];

      if (column && column.executeAction) {
        // preventing default navigation for links - use executeAction instead (fix for IE)
        e.preventDefault();

        const data = self.getData()[index];

        if (column.enableAction && !actionHandler.executeAction(column.enableAction, data, e)) {
          return;
        }

        const result = column.executeAction(data, e);
        if (result === false) {
          e.stopPropagation();
        }
      }
    });

    if (this.isSelectable()) {
      // handle selection via clicking on row
      this.$table.on("click", "tbody td", (e) => {
        // let input do it's thing
        if (
          INPUT_MATCHER.test(e.target.nodeName) ||
          (e.target.nodeName === "SPAN" && e.target.parentNode.nodeName === "A")
        ) {
          return;
        }

        const $tr = $(e.target).closest("tr[data-index]");
        const index = parseInt($tr.attr("data-index"), 10);
        const item = self.getData()[index];

        if (!item) {
          return;
        }

        if ("$$disabled" in item && item.$$disabled()) {
          return;
        }

        if ("$$selectable" in item && item.$$selectable === false) {
          return;
        }

        // toggling this value will trigger all of the required updates
        item.$$selected(!item.$$selected());
        e.stopPropagation();
      });
    }

    this._handleScrolling();

    if (this.options.fixedHeader) {
      // this needs to happen after bindings are in place so defer until page is ready
      onReady(() => {
        if (self.options.visible()) {
          self.$thead.fixedElement({
            fixedClass: FIXED_HEADER_CLASS,
            fixWhenOnScreenOnly: true,
            parentContainer: self.$element
          });
        }
      });
    }

    if (this._inModal || this._inPicker) {
      let lastWidths;
      const modalBody = this.$element.closest(".modal-body")[0];
      let wasVisible;

      // throttling function to force function to run at end of stack to prevent
      // circular updates noticed in Chrome 64+ (ATL-5413)
      this.resizeObserver = new ResizeObserver(
        jsUtils.throttleRender((entries) => {
          const isVisible = self.$element.is(":visible");

          // we only care about resizing if the width changes or transitions to being visible
          // Calculate tolerance dynamically based on current window.innerWidth
          const tolerancePercentage = 0.6; // 60% of window.innerWidth
          const tolerance = window.innerWidth * tolerancePercentage;

          if (
            entries &&
            lastWidths &&
            wasVisible === isVisible &&
            lastWidths.length === entries.length &&
            lastWidths.every((h, i) => Math.abs(h - entries[i].contentRect.width) < tolerance)
          ) {
            return;
          }

          // this will resize the grid when the modal is manually resized by the user
          if (isVisible) {
            this.resize();
          }
          lastWidths = entries && entries.map((e) => e.contentRect.width);
          wasVisible = isVisible;
        })
      );

      this.resizeObserver.observe(modalBody);
    } else {
      let wasVisible;

      this.windowResizeHandler = $(window).on("resizeCompleted-x", this.resize.bind(this));

      this.resizeObserver = new ResizeObserver(
        jsUtils.throttleRender(() => {
          const isVisible = self.$element.is(":visible");
          if (isVisible && isVisible !== wasVisible) {
            this.resize();
          }

          wasVisible = isVisible;
        })
      );

      this.resizeObserver.observe(this.$wrapper[0].parentNode);
    }

    // when visibility is toggled, trigger a resize event.
    self._disposables.push(
      self.options.visible.subscribe((visible) => {
        const $fixedElement = self.$thead.data("fixedElement");
        if ($fixedElement) {
          $fixedElement.dispose();
        }

        if (visible) {
          self._hasHeader = true;
          if (self.options.fixedHeader) {
            self.$thead.fixedElement({
              fixedClass: FIXED_HEADER_CLASS,
              fixWhenOnScreenOnly: true,
              parentContainer: self.$element
            });
          }

          self.resize();

          // search box width may be set to 0 when visibility is set to false.
          // trigger resize handler when visibility is reset.
          self.search?.handleResize();
        } else {
          self._hasHeader = false;
        }
      })
    );

    const searchEnabled = this.controller && !this._inPicker;
    if (searchEnabled) {
      this.search = new GridSearch(this);
      this._disposables.push(this.search);
    }

    // resize fixed header columns' widths
    this.$thead.on("fixed.plex", () => {
      if (!self.hasLockedColumns) {
        self.resize();
      }
      self._adjustOverlayedHeader(self.$scrollParent.scrollLeft());
    });
  },

  _adjustOverlayedHeader: function (xPosition) {
    // inside of dialogs fixed headers are absolutely positioned so they do not need to be repositioned
    if (this.$headerTable.parent()[0].style.position === "fixed") {
      this.$headerTable.css({
        "margin-left": -xPosition,
        "margin-right": xPosition
      });
    }
  },

  _handleScrolling: function () {
    const self = this;
    let lastOffsetX = 0;

    this.$scrollParent.on("scroll.plex-grid", () => {
      const offsetX = self.$scrollParent.scrollLeft();
      if (offsetX !== lastOffsetX) {
        self._adjustOverlayedHeader(offsetX);

        // move resizers
        const resizers = self.$element.find(".resizer");
        if (resizers) {
          const resizerMargin = offsetX / resizers.length / self.options.resizerWidth;
          resizers.each(function () {
            $(this).css("margin-left", -resizerMargin);
          });
        }

        lastOffsetX = offsetX;
      }
    });
  },

  onDataChange: function (data) {
    // note: this method is run whenever the observable array is changed
    // and triggers a re-rendering of the data
    const self = this;

    if (this.renderPending) {
      this.renderPending.dispose();
      this.renderPending = null;
    }

    if (ko.isObservable(this.controller && this.controller.config.visible) && !this.controller.config.visible()) {
      this.renderPending = this.controller.config.visible.subscribeOnce(this.onDataChange.bind(this, data));
      return;
    }

    // if currently rendering, cancel current render and then process the data
    if (self.isRendering()) {
      // cancel loading and defer updating until the cancel is completed
      self.cancelPending(true);
      self.cancelPending.subscribeOnce(this.onDataChange.bind(this, data));
      return;
    }

    if (data.length > 0) {
      if (this.$ranking) {
        this.$ranking.sortable("enable");
      }

      this._render();
    } else {
      if (this.$ranking) {
        this.$ranking.sortable("disable");
      }

      this._showEmpty();
    }
  },

  toggleRowSelection: function (index, selected) {
    const $tr = this.$tbody.children("tr[data-index='" + index + "']");

    // if this is not a multi-select grid and we are selecting a new item
    // we need to clear out all prior selections
    if (!this.isCheckable() && selected) {
      this.$tbody.children("." + SELECTED_ROW_CLASS).removeClass(SELECTED_ROW_CLASS);
    }

    $tr.toggleClass(SELECTED_ROW_CLASS, selected);
  },

  toggleHeaderSelection: function (groupIndex, index, selected) {
    const selector = "tr[data-group-header='" + groupIndex + "." + index + "']:first";

    // remove all existing selected rows
    this.$table.find("tr[data-group-header]." + SELECTED_ROW_CLASS).removeClass(SELECTED_ROW_CLASS);
    if (selected) {
      this.$table.find(selector).addClass(SELECTED_ROW_CLASS);
    }
  },

  toggleRowCollapsed: function (index, collapsed) {
    const $tr = this.$table.find("tr[data-index='" + index + "']:first");
    $tr.toggleClass(COLLAPSED_ROW_CLASS, collapsed);
  },

  toggleHeaderCollapsed: function (index, collapsed, isChildGroup) {
    const selector = "tr[data-group-header-index='" + index + "']" + (isChildGroup ? "" : ":not([data-grid-expander])");
    this.$table.find(selector).toggleClass(COLLAPSED_ROW_CLASS, collapsed);
  },

  toggleFooterCollapsed: function (index, collapsed) {
    const selector = "tr[data-group-footer-index='" + index + "']";
    this.$table.find(selector).toggleClass(COLLAPSED_ROW_CLASS, collapsed);
  },

  toggleRowEnable: function (index, enable) {
    this.$table.find("tr[data-index='" + index + "']:first").toggleClass("selectable", enable);
  },

  dispose: function () {
    // clear all knockout subscriptions
    let sub;
    const $fixedElement = this.$thead.data("fixedElement");

    if (this.isRendering()) {
      this.cancelPending(true);
    }

    while ((sub = this._disposables.pop())) {
      sub.dispose();
    }

    if ($fixedElement) {
      $fixedElement.dispose();
    }

    this.$element.removeData("grid");

    if (this.windowResizeHandler) {
      $(window).off("resizeCompleted-x", this.windowResizeHandler);
    }

    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }

    if (typeof this.writer.dispose === "function") {
      this.writer.dispose();
    }
  },

  isSelectable: function () {
    return gridSelectionMode.isSelectable(this.options.selectionMode);
  },

  isCheckable: function () {
    return gridSelectionMode.isCheckable(this.options.selectionMode);
  },

  isCollapsible: function () {
    return !!this.options.collapsible;
  },

  isRankable: function () {
    return !!this.options.ranking;
  },

  getColumnOffset: function () {
    let offset = 0;

    if (this.isCheckable()) {
      offset++;
    }

    if (this.isRankable()) {
      offset++;
    }

    return offset;
  },

  getCells: function (index, groupInfo = null) {
    if (index === "sample") {
      // if there are no rows than this is likely due to rows being hidden
      // this hack will give us the row with the most columns which should
      // give us what we need
      const sampleRow = [
        ...this.$tbody.children(":visible").toArray(),
        ...this.$table.find("thead").children(":visible").toArray()
      ].sort((a, b) => b.children.length - a.children.length)[0];
      return $(sampleRow).children();
    }

    if (groupInfo !== null) {
      if (GroupRowTypes.header === groupInfo.rowType) {
        return this.$table.find(
          `tbody tr[data-group-header-index='${groupInfo.groupIndex}'][data-group-header-row-index=${index}]:first > td`
        );
      } else if (GroupRowTypes.footer === groupInfo.rowType) {
        return this.$table.find(
          `tbody tr[data-group-footer-index='${groupInfo.groupIndex}'][data-group-footer-row-index=${index}]:first > td`
        );
      }
    }

    if (index == null) {
      return this.$table.find(`tbody tr[data-index]:first > td`);
    }

    return this.$table.find(`tbody tr[data-index=${index}]:first > td`);
  },

  _render: function (data) {
    htmlUtils.releaseMemoized(this.$element[0]);
    emptyElement(this.$tbody[0]);
    this.$element.trigger("rendering.plex.grid", [this]);

    // normally data is not passed in except for the case of rendering empty records
    data = data || this.data();

    this.empty();
    if (this.writer.prerender(null, 0, data)) {
      this.writer.render(data);
    }
  },

  print: function (data) {
    // no writer provider
    if (!this.options.printWriterProvider) {
      return "";
    }

    // create print writer
    this.printWriter = WriterFactory.create(this.options.printWriterProvider, this);

    data = data || this.data();
    if (this.printWriter.prePrint(null, 0, data)) {
      return this.printWriter.print(data);
    }

    return "";
  },

  setupValidation: function () {
    const self = this;
    const controller = plexImport("currentPage")[self.options.id];

    if (controller && controller.setupValidation && controller.validator) {
      // this needs to be cleared every time because the last rendering
      // of elements will no longer exist - the validation must be
      // reinitialized for each element after rendering
      controller.$elements = {};

      // We no longer have to filter on visible columns since we iterate
      // through columns by using the data-col-index attribute
      const columns = self.columns;

      self.$tbody.find(validatableControlSelector).each(function () {
        const $element = $(this);
        const $td = $element.closest("td");

        let colIndex = parseInt($td.attr("data-col-index"), 10);
        if (isNaN(colIndex)) {
          colIndex = $td.index();
          // account for checkbox
          colIndex -= self.isCheckable() ? 1 : 0;
          // account for draggable column
          colIndex -= self.options.ranking ? 1 : 0;
        }

        let propertyName, friendlyName, column, j, masterColumnChildColumns;

        if (colIndex > -1) {
          // standard grid column
          if (columns[colIndex].propertyName) {
            propertyName = columns[colIndex].propertyName;
            friendlyName = ko.unwrap(columns[colIndex].headerName) || columns[colIndex].originalHeaderName;
          }
          // element grid column
          else if (columns[colIndex].elements) {
            propertyName = $element.attr("name");
            friendlyName = ko.unwrap(columns[colIndex].headerName) || columns[colIndex].originalHeaderName;
          }
          // master grid column
          else if (columns[colIndex].columns) {
            masterColumnChildColumns = columns[colIndex].columns;
            for (j = 0; j < masterColumnChildColumns.length; j++) {
              column = masterColumnChildColumns[j].column;
              if (column) {
                propertyName = $element.attr("name");
                friendlyName = ko.unwrap(columns[colIndex].headerName) || columns[colIndex].originalHeaderName;
                if (propertyName != null) {
                  break;
                }
              }
            }
          }
        }

        if (propertyName) {
          this.name = this.name || this.id;
          controller.$elements[propertyName] = controller.$elements[propertyName] || [];
          controller.$elements[propertyName].push($element);

          $element.data(propertyName, friendlyName || "");
        }
      });

      $.each(controller.$elements, (propertyName) => {
        controller.validator.initPropertyValidation(propertyName);
      });
    }
  },

  empty: function () {
    emptyElement(this.$tbody[0]);
    emptyElement(this.$tfoot[0]);
  },

  onRenderStart: function () {
    // check to see if loading has been cancelled by a user interaction
    // if so get out and notify listeners
    if (this.cancelPending()) {
      this.onRenderCancel();
      return false;
    }

    this.unlockWidths();
    this.isRendering(true);
    return true;
  },

  onRenderCancel: function () {
    this.cancelPending(false);
    this.isRendering(false);
    this.$element.trigger("renderCancelled.plex.grid");
  },

  onRenderComplete: function () {
    const self = this;
    this.isRendering(false);
    this.setupValidation();

    if (this.data().length > 0) {
      this._indexData();
    }

    this.$element.trigger("rendered.plex.grid");

    // defer resize to give header a chance to redraw if needed
    jsUtils.defer(() => {
      // If parent container is not visible (feature applied) - no need to resize
      if (self.$element.is(":visible")) {
        self.resize();
      }
    });

    if (typeof self.controller.renderComplete === "function") {
      self.controller.renderComplete(true);
    }
  },

  onRenderBatch: function () {
    // trigger any listeners
    this.isRendering(false);
    this.setupValidation();
  },

  _indexData: function () {
    const data = this.getData();
    let i = data.length;

    while (i--) {
      data[i].$$index = i;
    }

    this._updateRowCssClass();
  },

  _updateRowCssClass: function () {
    for (let i = 0, ln = this.$tbody[0].rows.length; i < ln; i++) {
      const $row = $(this.$tbody[0].rows[i]);
      if (!$row[0].hasAttribute("data-index")) {
        continue;
      }

      $row.removeClass("plex-grid-row-odd plex-grid-row-even");
      $row.attr("data-index") % 2 === 0 ? $row.addClass("plex-grid-row-odd") : $row.addClass("plex-grid-row-even");
    }
  },

  getData: function () {
    return this.groups.length > 0 ? this.data().source : this.data();
  },

  _initResizers: function () {
    const self = this;
    let offset = 0;
    const columnOffset = this.getColumnOffset();
    const resizerOffset = self.options.resizerWidth / 2;
    const zindex = this.$wrapper.zIndex() + 1;

    const state = pageHandler.restoreState(this.options.id) || {};
    const gridColumns = self.columns.filter((c) => c.visible());
    let wasResized = !!state.isResized;

    // remove all current resizers
    this.$wrapper.find(".resizer").remove();

    const minWidths = this.getCells()
      .map(function (index) {
        const $cell = $(this);
        const column = gridColumns[index - columnOffset];

        // setup min-width for the element columns
        if (column && ((column.elements && column.elements.length > 0) || column.type === "Master")) {
          if ($cell.find(":input:first").length > 0) {
            // note that this is not a completely reliable check since other cells might have an input
            // probably the more robust way to handle this would be to add an attribute on the element
            // instances which would indicate whether they are resizable
            // this check allows VP grids, which use elements for everything, to be resizable
            return $cell[0].getBoundingClientRect().width;
          }
        }

        return MIN_COLUMN_SIZE;
      })
      .get();

    self.$thead.find("col").each(function (i) {
      const $col = $(this);
      const columnWidth = $col.width();
      if (columnWidth) {
        $("<div class='resizer'><div class='bar'></div></div>")
          .css({
            left: offset + columnWidth - resizerOffset,
            width: self.options.resizerWidth,
            zIndex: zindex
          })
          // the plugin will try to set focus, which will cause the browser to scroll to the top
          // of the resizer in IE, so prevent that from happening
          .on("focus", (e) => e.preventDefault())
          .data("index", i)
          .draggable({
            axis: "x",
            addClasses: false,
            scroll: false,
            start: function () {
              $(this).addClass("dragging");

              self.$headerTable.add(self.$table).addClass("resized");

              if (typeof this.setActive === "function") {
                // this is an IE only function - this is used
                // to prevent focus being set by the draggable
                // plugin which in IE ends up scrolling to the
                // top of the active element
                // see IP-4788
                this.setActive();
              }
            },
            stop: function (e, pos) {
              $(this).removeClass("dragging");

              // note that we cannot cache because rows can be added/removed from the grid
              // as the user scrolls
              const $cells = self.getCells("sample");

              let diff = pos.originalPosition.left - pos.position.left;
              const $cell = $($cells[i]);
              const cellMinWidth = parseInt($cell.css("min-width"), 10);
              const minWidth = Math.max(cellMinWidth, minWidths[i] || MIN_COLUMN_SIZE);
              const columnSize = $col.width();

              let resizedColumnWidth = columnSize - diff - resizerOffset;
              if (resizedColumnWidth < minWidth) {
                diff = columnSize - minWidth;
                resizedColumnWidth = minWidth;
              }

              if (diff) {
                const $tableCol = self.$table.find(`col:eq(${i})`);

                // resize header & grid columns
                $col.add($tableCol).width(resizedColumnWidth);

                // make columns match
                $cell.css("width", $tableCol.css("width"));

                // resize table
                const gridWidth = self.$table[0].getBoundingClientRect().width - diff;
                self.$table.width(gridWidth);

                // set grid wrapper new width
                self.$wrapper.css("max-width", gridWidth);

                // set fixed grid header new width
                self.$headerTable.add(self.$wrapper).add(self.$wrapper.parent()).add(self.$thead).width(gridWidth);

                wasResized = true;
                $col.trigger("columnResized", { grid: self });
              } else if (!wasResized) {
                self.$headerTable.add(self.$table).removeClass("resized");
              }

              // redraw resizers
              let resizePosition = 0;
              self.$wrapper.find(".resizer").each(function (index) {
                resizePosition += $cells[index].getBoundingClientRect().width;
                this.style.left = resizePosition - resizerOffset + "px";
              });
            }
          })
          .appendTo(self.$wrapper);
      }

      offset += columnWidth;
    });
  },

  resizeColumns: function () {
    this.unlockWidths();
    this.lockWidths();
  },

  lockWidths: function () {
    const self = this;
    if (!this._hasHeader || !this.$element.is(":visible")) {
      return;
    }

    let widths = [];

    // clear width because this will impact the width of the first column within dialogs
    this.$tbody.children(".plex-virtual-placeholder").width("");

    // get a sample row - this will decide how the grid is sized
    this.getCells("sample").each(function () {
      widths.push(this.getBoundingClientRect().width);

      if (!this.style.width || this.style.width === "auto") {
        const $images = $(this).find("img");
        if ($images.length > 0) {
          $images.each(function () {
            if (!this.complete && !this.naturalWidth) {
              // if the image is not yet loaded reset the widths once it has been loaded
              $(this).one("load", self.resizeColumns.bind(self));
            }
          });
        }
      }
    });

    // restore column width state if it exists
    const state = pageHandler.restoreState(this.options.id);
    if (state && state.columnsWidth) {
      const restoredWidths = restoreSavedColumnWidth(state, this.controller, this.getColumnOffset());
      if (restoredWidths.length === widths.length) {
        this.$headerTable.add(this.$table).toggleClass("resized", !!state.isResized);

        widths = restoredWidths;
      }
    }

    const colGroup = "<colgroup>" + widths.map((w) => `<col style='width:${w}px;'></col>`).join("") + "</colgroup>";

    this.$headerTable.prepend(colGroup);
    this.$table.prepend(colGroup);

    // chrome will resize the grid to fit within the window if the width isn't restored
    const currentWidth = widths.reduce((total, width) => total + width, 0);
    this.$table.css("table-layout", "fixed");
    this.$table.width(currentWidth);

    // firefox needs the parent to grow or the width will be clipped
    if (!this._inModal) {
      this.$table.parent().css("max-width", currentWidth + "px");
    }

    // reset the placeholder widths
    this.$table.children().find("tr.plex-virtual-placeholder").width(currentWidth);
    this.hasLockedColumns = true;

    if (this.options.resizable) {
      this.$headerTable.css("table-layout", "fixed");
      this.$headerTable.width(currentWidth);

      this._initResizers();
    }
  },

  unlockWidths: function () {
    this.$table.find("colgroup").remove();
    this.$thead.find("colgroup").remove();

    this.$table.css("table-layout", "auto");
    this.$headerTable.css("table-layout", "auto");

    // restore width styling
    this.$table.width("");
    this.$headerTable.width("");
    this.$table.parent().css("max-width", "");
    this.hasLockedColumns = false;
  },

  resize: function () {
    let height = 0;
    const self = this;

    if (!this._hasHeader) {
      return;
    }

    if (this.hasLockedColumns) {
      this.resizeColumns();
    }

    // move header up to account for fixed header
    const resetHeight = function () {
      if (self.options.fixedHeader) {
        height = self.$thead.height();
        self.$table.css("margin-top", `-${height}px`);
      }
    };

    resetHeight();

    this.$headerTable.width("auto");

    // make sure the table's layout is the same
    const layout = self.$table.css("table-layout");
    if (layout === "fixed") {
      this.$headerTable.css("table-layout", "fixed");
    } else {
      // resize fixed header columns to match the grid
      self.$table
        .children("thead")
        .find("th")
        .each(function (i) {
          const fixedHeaderCol = self.$thead.find("th:eq(" + i + ")")[0];
          const origHeaderColDimensions = this.getBoundingClientRect();
          let width;

          if (origHeaderColDimensions.width) {
            width = origHeaderColDimensions.width;
          } else {
            width = origHeaderColDimensions.right - origHeaderColDimensions.left;
          }

          fixedHeaderCol.style.width = width + "px";
        });
    }

    // toggle the fixing of the width of the grid's container - this is to handle situations
    // where the headers can become out of sync - this is only known to happen in modals
    this.$headerTable.width(self.$table.width());

    // If we don't do this only before width resize, we have rows that are hidden if
    // columns wrap. If we do this only after width resize, we have column alignment
    // issues.
    resetHeight();
  },

  _showEmpty: function () {
    // no longer showing empty rows, so clear and resize to reset headers
    this.empty();
    this.onRenderStart();
    this.onRenderComplete();
  },

  filterGroupColumns: function ($gridElement) {
    const $groupCells = $gridElement.find(".plex-grid-column-group-child");
    const $headerCells = $gridElement
      .find(".plex-grid-header-cell, .plex-grid-column-group[colspan != '1']")
      .not(".plex-grid-column-group-child");

    if ($groupCells.length > 0) {
      let i = 0;
      const filteredCells = [];
      $headerCells.each(function () {
        const $th = $(this);
        if ($th.hasClass("plex-grid-column-group")) {
          const colspan = $th.attr("colspan");
          if (colspan) {
            let j = 0;
            for (; j < colspan; j++) {
              filteredCells.push($groupCells[i + j]);
            }

            i = j;
          }
        } else {
          filteredCells.push($th[0]);
        }
      });

      return $(filteredCells);
    }

    return $headerCells;
  },

  resetLayout: function () {
    this.$element.triggerHandler("layoutChanged.plex.grid", {
      columns: this.columns
    });
  },

  _initRanking: function () {
    const fixHelperModified = function (e, tr) {
      const $originals = tr.children();
      const $helper = tr.clone();
      $helper.children().each(function (index) {
        $(this).width($originals.eq(index).outerWidth());
      });
      return $helper;
    };

    const controller = plexImport("currentPage")[this.options.id];

    const self = this;

    this.$ranking = this.$tbody.sortable({
      axis: "y",
      helper: fixHelperModified,
      forceHelperSize: true,
      disabled: true,
      update: function (event, ui) {
        const oldIndex = parseInt(ui.item.attr("data-index"), 10);

        let newIndex = 0;
        const aboveElementIndex = parseInt(ui.item.prevAll("[data-index]").attr("data-index"), 10);
        const belowElementIndex = parseInt(ui.item.nextAll("[data-index]").attr("data-index"), 10);

        // did it move to top?
        if (isNaN(aboveElementIndex)) {
          newIndex = 0;
        }
        // did it move to bottom?
        else if (isNaN(belowElementIndex)) {
          newIndex = aboveElementIndex;
        }
        // did it move down?
        else if (oldIndex < aboveElementIndex) {
          newIndex = aboveElementIndex;
        }
        // did it move up?
        else if (oldIndex > aboveElementIndex) {
          newIndex = belowElementIndex;
        }

        // Cancel jQuery UI handling. Rendering is handled via knockout.
        self.$tbody.sortable("cancel");

        const context = {
          oldIndex,
          newIndex,
          cancelRanking: false,
          promise: null
        };

        self.$element.trigger("beforeRanking.plex.grid", [this, context]);
        controller.fire("beforeRecordRanked", context);

        if (context.cancelRanking === false) {
          const rankRecord = function () {
            // Let knockout do its job.
            controller.rankRecord(oldIndex, newIndex);
            self.$element.trigger("afterRanking.plex.grid", [this, { oldIndex, newIndex }]);
          };

          if (context.promise) {
            context.promise.done(rankRecord);
          } else {
            rankRecord();
          }
        }
      }
    });
  },

  _getScrollOffset: function ($row) {
    const fixedElementsOffset = Math.max(this.$element.fixedOffset(), this.$scrollParent.fixedOffset());
    const rowTop = $row.offset().top;

    // jQuery offset() adds window.pageYOffset.
    // When scroll parent is document, don't add window.pageYOffset again.
    if (this.$scrollParent[0] === document) {
      return [rowTop - fixedElementsOffset, fixedElementsOffset];
    }

    return [this.$scrollParent.scrollTop() + rowTop - fixedElementsOffset, fixedElementsOffset];
  },

  scrollTo: function (index, callback, force, groupInfo = null) {
    callback = callback || $.noop;

    if (this.writer.virtual && !force) {
      const ensureIndex = groupInfo === null ? index : groupInfo.groupRecordIndex;

      this.writer.ensureRendered(ensureIndex, () => this.scrollTo(index, callback, true, groupInfo));
      return;
    }

    const $row = this.getCells(index, groupInfo);

    if ($row.length > 0) {
      const [scrollAmount, fixedElementsOffset] = this._getScrollOffset($row);
      // target html & body if the grid is fullscreen to account for IE & Firefox
      const $parent = this.$scrollParent[0] === document ? $("html, body") : this.$scrollParent;
      $parent
        .animate({ scrollTop: scrollAmount }, 250, "swing")
        .promise()
        .then(() => {
          // adjust if the scrolling caused elements to be fixed/unfixed
          const [newSrollAmount, newFixedElementsOffset] = this._getScrollOffset($row);
          if (newFixedElementsOffset !== fixedElementsOffset) {
            $parent.scrollTop(newSrollAmount);
          }
          callback($row);
        });
    }
  }
};

$.fn.grid = function (options) {
  const args = $.makeArray(arguments);

  return this.each(function () {
    const $this = $(this);
    const data = $this.data("grid");

    if (!data) {
      $this.data("grid", new Grid(this, ...args));
    } else if (typeof options === "string" && options in data) {
      data[options].apply(data, args.slice(1));
    }
  });
};

$.fn.grid.Constructor = Grid;
