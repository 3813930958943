/* eslint-disable react-hooks/rules-of-hooks */
const $ = require("jquery");
const ko = require("knockout");
const validation = require("../Core/plex-validation");
const pageHandler = require("./plex-handler-page");
const actionHandler = require("./plex-handler-action");
const pubsub = require("../Core/plex-pubsub");
const domUtils = require("../Utilities/plex-utils-dom");
const jsUtils = require("../Utilities/plex-utils-js");
const arrayUtils = require("../Utilities/plex-utils-arrays");
const printing = require("../Core/plex-printing");
const modelRepository = require("./plex-model-repository");
const nav = require("../Core/plex-navigate");
const banner = require("../Plugins/plex-banner");
const DocumentXml = require("../Utilities/plex-utils-documentxml");
const Controller = require("./plex-controller-base");
const pageState = require("../Core/plex-pagestate");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");
const sessionStorage = require("../Core/plex-sessionstorage");
const parseJSON = require("../Core/plex-parsing-json");
const parseURL = require("../Core/plex-parsing-url");
const env = require("../Core/plex-env");

require("../Plugins/plex-jquery"); // eslint-disable-line import/no-unassigned-import
require("../Knockout/knockout-extender-and-or"); // eslint-disable-line import/no-unassigned-import

const LEFT_CLICK = 1;

// The cutoff is defined in the LESS media query - these should be kept in sync
const THREE_COLUMN_CUTOFF = 1350 - $.getScrollBarSize().width;
const TWO_COLUMN_CUTOFF = 800 - $.getScrollBarSize().width;
const PHONE_CUTOFF = 480 - $.getScrollBarSize().width;

const CSS_LAYOUT = {
  "plex-filter-one-column-phone": {
    "max-width": PHONE_CUTOFF - 1
  },
  "plex-filter-one-column": {
    "min-width": PHONE_CUTOFF,
    "max-width": TWO_COLUMN_CUTOFF - 1
  },
  "plex-filter-two-column": {
    "max-width": THREE_COLUMN_CUTOFF - 1,
    "min-width": TWO_COLUMN_CUTOFF
  },
  "plex-filter-three-column": {
    "min-width": THREE_COLUMN_CUTOFF
  }
};

const FilterController = Controller.extend({
  events: ["searching", "searched"],

  onPreStateRestore: function () {
    this.saveOriginalData();
  },

  onInit: function () {
    const self = this;

    // save data to repository
    this.data.$$controller = this;
    modelRepository.add(this.config.id, this.data);

    self.config.usesFixedActionbar = true;

    const hasState = !!self.savedState;

    const validationModel = self.config.validationModel || {};
    self.validator = validation.createValidator(self.$element.parents(".plex-filter"), validationModel, self);
    self.commitTrigger = ko.observable();
    self.rollbackTrigger = ko.observable();

    self.isPinned = ko.observable(sessionStorage.getItem("filterPinned", { cacheParsers: false }) || false);

    self.filters = {};
    self.isLoading = ko.observable(false);

    self.filtersShowing = ko.observable(true);

    self.$elements = {};
    self.visible = ko.observable(self.config.clientVisible);

    self.hasSavedDefaults = ko.observable(self.config.filterDefaultJson);

    self.showSaveAsDefault = ko.pureComputed(() => {
      return self.config.canSaveDefaults && self.config.loadDefaultFilters && !self.hasSavedDefaults();
    });

    self.filterMessage = ko.pureComputed(() => {
      // "Hide filters" : "Show all filters";
      return self.filtersShowing() ? self.config.hideFiltersText : self.config.displayFiltersText;
    });

    // Removing empty objects from the serialized model is very
    // important for "auto" hiding filters based on customer settings.
    arrayUtils.removeEmpty(self.config.elements);

    self.config.elements.forEach((filter) => {
      filter.searched = ko.observable(false);

      filter.features.push({ name: "FilterToggle" });
      self.initElement(filter);

      const filterVisible = ko.pureComputed(() => {
        if (!filter.elements) {
          return true;
        }

        return filter.elements.some((el) => ko.unwrap(el.visible));
      });

      // hide filter if all child elements are hidden
      filter.visible = filter.visible.extend({ and: filterVisible });

      // todo: do we need both of these? need to distinguish between id & property better
      self.filters[filter.id] = self.elements[filter.id] = filter;
    });

    self.filtersShowing.subscribe((showing) => {
      if (!showing) {
        // Reset focus for elements that where focused but hidden.
        // This is for IE, which shows a floating caret.
        const $focused = $(":focus");
        if ($focused.length > 0 && $focused.is(":hidden")) {
          domUtils.setInitialFocus(self.$element);
        }
      }
    });

    this.prepareData();

    self.searchAction = self.config.searchAction;
    if (self.searchAction && self.searchAction.actions.length > 0) {
      self.searchAction.actions.forEach((action) => {
        action.publishSearch = true;
        actionHandler.initAction(action, self);
      });
    }

    // whenever a required changes, trigger a prepareFilters
    pubsub.subscribe("requiredChanged_" + self.config.id, () => {
      updateFilters(self.config.elements, self.originalData, filterHasValueOrRequired);
    });

    // initialize events
    this.setupEvents(this.config);

    // initialize features
    if (self.config.features && self.config.features.length > 0) {
      self.features = self.config.features;
      this.setupFeatures(self);
    }

    this.$element.parent().fixedElement({ horizontal: true, vertical: false });

    // Check to see if filter properties exist in the querystring
    const useQueryStringFilters = function (jsonFilter) {
      const queryStringArgs = parseURL.parseQueryString(window.location.href);
      return !!Object.keys(queryStringArgs).find((prop) => {
        return Object.prototype.hasOwnProperty.call(jsonFilter, prop);
      });
    };

    const loadElements = function (data) {
      pageHandler.setState(self.config.id, data);
      self.restoreState(data);
      self.setupBindAndSearch(hasState);
    };

    const loadSaveAsDefaultCallback = function () {
      const featFlag = env.features["fix-tri-4934-save-as-default-for-filters"];
      const defaultFilters = self.config.filterDefaultJson ? parseJSON(self.config.filterDefaultJson) : {};
      const queryFilters = useQueryStringFilters(self.config.data);

      if (featFlag && !hasState) {
        // Merge default and query filters
        const finalFilters = { ...defaultFilters, ...queryFilters };
        loadElements(finalFilters);
      } else if (self.config.filterDefaultJson && !queryFilters && !hasState) {
        loadElements(parseJSON(self.config.filterDefaultJson));
      } else {
        self.setupBindAndSearch(hasState);
      }
    };

    if (self.config.loadDefaultFilters) {
      this.loadFavoriteElementDefaults().then(loadElements, loadSaveAsDefaultCallback);
    } else {
      self.setupBindAndSearch(hasState);
    }
  },

  setupBindAndSearch: function (alreadyHasState) {
    const self = this;

    // bind model
    ko.applyBindings(self, self.$element[0]);

    if (domUtils.isInDialog(this.$element)) {
      // use this binding to manually apply the column classes
      // based on the dialog size
      ko.applyBindingsToNode(this.$element[0], { mediaQuery: CSS_LAYOUT });
    } else {
      // save scroll position before page is unloaded
      window.addEventListener("beforeunload", () => {
        pageState.addToCurrentState("scrollPosition", this.$element.scrollParent().scrollTop());
      });
    }

    if (alreadyHasState || self.config.autoSearch) {
      self.search();
    }
  },

  onResize: function () {
    // can be overriden
  },

  search: function (_vm, e) {
    if (e && e.which && e.which !== LEFT_CLICK) {
      // since this method is wired up using a mousedown event, it will fire when any button is pushed
      // so add a check to mimic the expected behavior for a click event
      return true;
    }

    // the sole purpose of this delay is to let the picker win if they are racing
    // if the user clicks on the search button mid pick this will insure that the
    // pick finishes before the search is invoked - but the search will be invoked
    // afterwards
    jsUtils.defer(this.onSearch, this);

    if (e && e.target) {
      // hack: for some reason this event blocks the click event from firing
      // i haven't been able to figure out, but forcing a click solves the issue
      // at hand and is unlikely to cause other issues. this corresponds to
      // ticket IP-4538
      $(e.target).click();
    }

    return true;
  },

  onSearch: function () {
    // if any controls are busy wait until they are finished and *then* invoke the search
    if (ko.isObservable(this.isBusy) && this.isBusy()) {
      this.isBusy.subscribeOnce(this.onSearch, this);
      return;
    }

    // update history with new state
    pageHandler.setState(this.config.id, this.getState());

    if (this.searchAction && this.searchAction.actions.length > 0) {
      this.onSearchStarting();
      const searchResolvers = this.searchAction.actions.map((action) => {
        if (typeof action.showOverlay === "undefined") {
          action.showOverlay = false;
        }

        return actionHandler.executeAction(action, this.data);
      });

      $.when(...searchResolvers)
        .fail((err) => this.onSearchFailed(err))
        .done(() => this.onSearchCompleted());
    }
  },

  onSearchStarting: function () {
    this.commitTrigger.notifySubscribers();
    this.isLoading(true);
    this.fire("searching", this.data);

    // if scroll position was saved from previous state, fire a one time event
    // when grid is completely loaded to adjust scroll position
    if (!domUtils.isInDialog(this.$element)) {
      const previousScrollPosition = pageState.getCurrentState().scrollPosition;
      if (previousScrollPosition) {
        this.pageRenderedSubscription = pubsub.subscribe("page rendered", (_e) => {
          applyPreviousScrollPosition(previousScrollPosition, this.$element.scrollParent());
        });
      }
    }
  },

  onSearchCompleted: function () {
    updateFilters(this.config.elements, this.originalData, filterHasValue);

    if (ko.unwrap(this.isPinned) === false) {
      this.filtersShowing(false);
    }

    this.saved(true);
    this.isLoading(false);

    // reset dirty flags now that search has been completed.
    if ("$$dirtyFlag" in this.data) {
      this.data.$$dirtyFlag.reset();
    }

    this.fire("searched", this.data);
    this.pageRenderedSubscription && pubsub.unsubscribe(this.pageRenderedSubscription);
  },

  onSearchFailed: function (err) {
    this.rollbackTrigger.notifySubscribers();
    this.filtersShowing(true);
    this.isLoading(false);
    let message;

    if (err) {
      message = typeof err === "string" ? err : err.message;

      if (message) {
        const bindingErrorCheck = /Binding numericValue not found/.test(message);

        if (isVisionPlexRequest() && bindingErrorCheck) {
          message = "The Data Value property is not set on a Number box element in the grid.";
        }
      }

      if (message) {
        const error = typeof err === "object" && err.name === "ClientError" ? err : message;
        banner.getBanner().setMessage(error, { status: banner.states.error });
      }
    }
  },

  toggleFilters: function () {
    this.filtersShowing(!this.filtersShowing());
  },

  saveDefaults: function () {
    const self = this;

    let actionKey = self.config.cloudApplicationActionKey;
    if (actionKey == null) {
      actionKey = plexImport("currentApplication").ActionKey;
    }

    const filterDefaults = {
      CloudApplicationActionKey: actionKey,
      DefaultJSON: self.getDbFriendlyFilterState()
    };

    const promise = nav.post(nav.buildUrl("/Platform/FilterDefault/Update"), filterDefaults, {
      ajax: this.ajax
    });

    promise.done(() => {
      self.hasSavedDefaults(true);
      banner.getBanner().setMessage("Defaults have been saved.", { status: banner.states.success });
    });
  },

  clearDefaults: function () {
    const self = this;

    let actionKey = self.config.cloudApplicationActionKey;
    if (actionKey == null) {
      actionKey = plexImport("currentApplication").ActionKey;
    }

    const request = {
      CloudApplicationActionKey: actionKey
    };

    nav.post("/Platform/FilterDefault/Delete", request, { ajax: this.ajax }).done(() => {
      self.hasSavedDefaults(false);
      banner.getBanner().setMessage("Defaults have been deleted.", { status: banner.states.success });
    });
  },

  getDbFriendlyFilterState: function () {
    return JSON.stringify(this.getState(false, true));
  },

  toDocumentXml: function () {
    const elements = printing.filterElements(this.config.elements);
    if (elements.length === 0) {
      return "";
    }

    const doc = new DocumentXml("plex-filter-panel");

    elements.forEach((filter) => {
      const filterControls = doc.createNode("plex-filter");
      if (filter.searched()) {
        const controls = filterControls.addControlElement(filter.label).createNode("plex-controls");

        printing.filterElements(filter.elements).forEach((filterElement) => {
          controls.addControlElement(filterElement);
        });
      }
    });

    return doc.serialize();
  },

  togglePinning: function (_data, _event) {
    const self = this;
    const previousValue = !!ko.unwrap(self.isPinned);
    const newValue = !previousValue;
    sessionStorage.setItem("filterPinned", newValue);
    self.isPinned(newValue);
  }
});

FilterController.create = function () {
  return new FilterController();
};

function isVisionPlexRequest() {
  const currentApplication = plexImport("currentApplication");

  if (
    currentApplication.AreaName.toLowerCase() === "visionplex" &&
    currentApplication.ControllerName.toLowerCase() === "screen"
  ) {
    return true;
  }

  return false;
}

// #region helpers

function isNotEmpty(value, originalValue) {
  const unwrapped = ko.unwrap(value);

  // if an original value was truthy but is now false, we want it to show up
  if (unwrapped || unwrapped === 0 || (unwrapped === false && originalValue)) {
    // if array, make sure that it is not empty
    return !Array.isArray(unwrapped) || unwrapped.length > 0;
  }

  return false;
}

function elementIsRequired(el) {
  const requiredState = el.required();
  return (
    requiredState &&
    (requiredState === validation.requiredStates.required || requiredState === validation.requiredStates.requireGroup)
  );
}

function updateFilters(filters, original, searchedPredicate) {
  filters
    .filter((filter) => filter.isSearchCriteria !== false)
    .forEach((filter) => filter.searched(searchedPredicate(filter, original)));
}

function filterHasValue(el, original) {
  let i;
  let originalValue;

  if (el.controller && el.controller.isFilterVisible) {
    return el.controller.isFilterVisible();
  }

  if (el.boundValue) {
    originalValue = el.propertyName ? original[el.propertyName] : null;
    if (isNotEmpty(el.boundValue, originalValue)) {
      return true;
    }
  }

  if (el.boundDisplayValue) {
    originalValue = el.displayPropertyName ? original[el.displayPropertyName] : null;
    if (isNotEmpty(el.boundDisplayValue, originalValue)) {
      return true;
    }
  }

  if (el.elements && el.elements.length > 0) {
    i = el.elements.length;
    while (i--) {
      if (filterHasValue(el.elements[i], original)) {
        return true;
      }
    }
  }

  return false;
}

function filterHasValueOrRequired(el, original) {
  let i, originalValue;

  if (el.boundValue) {
    originalValue = el.propertyName ? original[el.propertyName] : null;
    if (isNotEmpty(el.boundValue, originalValue) || elementIsRequired(el)) {
      return true;
    }
  }

  if (el.boundDisplayValue) {
    originalValue = el.displayPropertyName ? original[el.displayPropertyName] : null;
    if (isNotEmpty(el.boundDisplayValue, originalValue) || elementIsRequired(el)) {
      return true;
    }
  }

  if (el.elements && el.elements.length > 0) {
    i = el.elements.length;
    while (i--) {
      if (filterHasValueOrRequired(el.elements[i], original)) {
        return true;
      }
    }
  }

  return false;
}

function applyPreviousScrollPosition(previousScrollPosition, $element) {
  if (previousScrollPosition) {
    if ($element.is(document)) {
      $("html").animate(
        {
          scrollTop: previousScrollPosition
        },
        400
      );
    } else {
      $element.animate(
        {
          scrollTop: previousScrollPosition
        },
        400
      );
    }
  }
}
// #endregion

module.exports = FilterController;
plexExport("FilterController", FilterController);
