const $ = require("jquery");
const { ulid } = require("ulid");
const client = require("./plex-component-host-client");
const ask = require("../Dialogs/plex-dialogs-ask");
const { isAuthorizationError } = require("./wamp-errors");
const plexImport = require("../../global-import");

const LABEL_PRINT_API = "com.plex.label_printing.print_label";
const CANCELLED_ERROR = { type: "CANCELLED", message: "User cancelled printer selection" };
const TOKEN_TIMEOUT_MS = 2 * 60 * 1000; // 2 minutes
let currentUserToken, componentEnabled, version;

function invokeHandlerOrContinue({ args }) {
  const resultOrUri = args[0];
  if (typeof resultOrUri === "string" || (resultOrUri && resultOrUri.uri)) {
    return client.invokeHandler(resultOrUri, "com.plex.label_printing");
  }

  return { args };
}

function normalizeError(msg) {
  return function (err) {
    if (!(err instanceof Error) && Array.isArray(err.args) && typeof err.args[0] === "string") {
      err = new Error(msg);
    }

    throw err;
  };
}

/** pre-1.4.0 user token */
function getUserToken() {
  const now = Date.now();

  // We reuse the token for a period of time in case multiple calls are triggered
  // from the same user interaction.
  if (!currentUserToken || now - currentUserToken.timestamp > TOKEN_TIMEOUT_MS) {
    currentUserToken = {
      token: ulid()
    };
  }

  currentUserToken.timestamp = now;
  return currentUserToken.token;
}

function startUserInteraction() {
  if (version && version.indexOf("1.1") !== 0 && !client.pchVersionAtLeast("1.4")) {
    // - Since the version 1.1.x doesn't support the user interaction flow, we want to avoid
    //   initiating it. It's not that it's harmful to make the call, but it adds an extra client
    //   interaction which could cause following interactions to be blocked.
    // - Starting with version 1.4, the user interaction flow is not used for most user RPCs.
    client.startUserInteraction(getUserToken());
  }
}

function endUserInteraction() {
  client.endUserInteraction();
}

class LabelPrinter {
  /**
   * Indicates whether the label component for the label host is installed or not.
   * @returns {Promise<Boolean>}
   */
  isEnabled() {
    // Right now we are caching this value - this may cause issues if the plugin is enabled
    // after a label request has been generated, but this will save overhead for users who
    // do not yet have the plugin enabled. We may want to alter this behavior once the web
    // socket plugin is fully deprecated.
    if (componentEnabled != null) {
      return $.when(componentEnabled);
    }

    // Make sure that RPC call is registered - this will verify that even
    // if the component host is running, that the label component is actually
    // installed and enabled.
    return client.rpc("wamp.registration.lookup", [LABEL_PRINT_API]).then(
      ({ args }) => {
        componentEnabled = !!args[0];
        if (componentEnabled) {
          return this.getVersion().then(() => true);
        }

        return componentEnabled;
      },
      () => (componentEnabled = false)
    );
  }

  getVersion() {
    if (client.pchVersion) {
      return $.when((version = client.pchVersion));
    }

    // Inferring the version based on the presence of this RPC called which was technically
    // added in 1.2. We should make an explicit version endpoint in the future.
    return client.rpc("wamp.registration.lookup", ["com.plex.label_printing.set_printer_by_id"]).then(
      ({ args }) => (version = args[0] ? "1.3" : "1.1"),
      () => (version = "1.1")
    );
  }

  handlePrintRequest(code, options, meta) {
    // eslint-disable-next-line no-param-reassign
    options = options || {};

    startUserInteraction();

    // plPrintPipeVersion 4/5
    if (options.PrinterName) {
      // eslint-disable-next-line prefer-const
      let [address, port] = options.PrinterName.split(":");
      port = port && parseInt(port, 10);
      return this.printLabel(code, { address, port }, meta)
        .catch(() =>
          // fallback to default printer
          this.handlePrintRequest(code, null, meta)
        )
        .always(endUserInteraction);
    }

    // plPrintPipeVersion 3
    if (options.UniqueIdentifier) {
      return this.getOrSetPrinter(options.UniqueIdentifier)
        .then(
          (printer) => this.printLabel(code, printer, meta),
          // Fallback to CH v1.1 behavior - this can be removed once v1.3 is broadly available
          () => this.handlePrintRequest(code, { PromptUserForPrinterSelection: true }, meta)
        )
        .always(endUserInteraction);
    }

    // plPrintPipeVersion 2
    if (options.PromptUserForPrinterSelection) {
      return this.selectPrinter()
        .then((choice) => this.printLabel(code, choice && choice.printer, meta))
        .always(endUserInteraction);
    }

    // plPrintPipeVersion 1
    return this.selectDefaultPrinter()
      .then((printer) => this.printLabel(code, printer, meta))
      .always(endUserInteraction);
  }

  routedRpc(procedure, args, kwargs) {
    return client.rpc(procedure, args, kwargs, client.routedWithUserTokenOpts);
  }

  getOrSetPrinter(id) {
    return client.rpc("com.plex.label_printing.get_printer_by_id", [id]).then((result) => {
      const printerName = result.args[0];

      if (printerName) {
        return { name: printerName };
      }

      // if printer not found, select and save selection
      return this.selectPrinter().then((choice) => {
        if (choice) {
          // we don't really need to wait for this to finish...
          client.rpc("com.plex.label_printing.set_printer_by_id", [id, choice.displayName]);
        }
        return choice && choice.printer;
      });
    });
  }

  selectPrinter() {
    return this.routedRpc("com.plex.label_printing.enumerate_printers")
      .then(invokeHandlerOrContinue)
      .then(({ args }) => {
        const choices = args[0].map((choice) => ({
          answerText: choice.displayName,
          answerValue: choice
        }));

        if (choices.length === 0) {
          throw new Error("No printers found on system");
        }

        // We are purposely ignoring if the user closes the selection.
        const deferred = new $.Deferred();
        ask("Available printers:", choices, null, {
          displayType: "list",
          title: "Select Printer"
        }).then(deferred.resolve, () => deferred.reject(CANCELLED_ERROR));

        return deferred.promise();
      })
      .catch(normalizeError("Unable to list printers"));
  }

  selectDefaultPrinter() {
    return this.routedRpc("com.plex.label_printing.get_user_default_printer")
      .then(invokeHandlerOrContinue)
      .then(({ args }) => {
        if (!args[0]) {
          throw new Error("Unable to determine default printer");
        }

        return args[0];
      })
      .catch(normalizeError("Unable to determine default printer"));
  }

  /**
   * Prints a ZPL label to the defined printer.
   * @param {String} zpl
   * @param {Printer} printer
   * @param {Object} meta
   * @returns {Promise}
   */
  printLabel(zpl, printer, meta = {}) {
    const appState = plexImport("appState") || {};
    let documentTitle;

    if (meta.LabelType && meta.LabelName && meta.LabelFormatKey) {
      documentTitle = `${meta.LabelType} (${meta.LabelName}) - ${meta.LabelFormatKey}`;
    } else {
      documentTitle = meta.LabelName || meta.LabelType || "Plex Label";
    }

    const jobInfo = {
      correlationId: meta.CorrelationId,
      documentTitle,
      pcn: appState.customer?.pcn,
      pun: appState.user?.pun,
      user: appState.user?.userId
    };

    const usingPersistentToken = client.pchVersionAtLeast("1.4");
    let userToken = null;

    const kwargsPromise = usingPersistentToken
      ? client.getPersistentUserToken().then((token) => {
          userToken = token;

          // eslint-disable-next-line camelcase
          return { user_token: token };
        })
      : $.when(null);

    return kwargsPromise
      .then((kwargs) => client.rpc(LABEL_PRINT_API, [{ code: zpl }, printer, jobInfo], kwargs))
      .then(invokeHandlerOrContinue)
      .catch((err) => {
        if (usingPersistentToken && isAuthorizationError(err)) {
          return client.getPersistentUserToken().then((token) => {
            if (token !== userToken) {
              // we have a fresh token, so let's retry
              return this.printLabel(zpl, printer, meta);
            }

            throw err;
          });
        }

        throw err;
      })
      .catch(normalizeError("There was a problem sending the job to the printer"));
  }
}

module.exports = new LabelPrinter();
