ï»¿const $ = require("jquery");
const TraceKit = require("tracekit");
const logger = require("./plex-logger");
const notify = require("./plex-notify");
const nav = require("./plex-navigate");
const stringUtils = require("../Utilities/plex-utils-strings");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

let enabled = true;
let lastError = null;

let windowonmessage = null;
let windowonerror = null;

function getPageState() {
  const pageState = [];
  const currentPage = plexImport("currentPage");

  Object.keys(currentPage).forEach((key) => {
    const controller = currentPage[key];
    if (controller.getState) {
      pageState.push({
        elementName: key,
        elementState: stringUtils.escapeHtml(JSON.stringify(controller.getState()))
      });
    }
  });

  return pageState;
}

function normalizeErrorStack(err) {
  if (!err.stack) {
    return;
  }

  err.stack = err.stack.map((entry) => {
    // do not include context - this contains the entire source
    return {
      args: entry.args,
      column: entry.column,
      line: entry.line,
      func: entry.func,
      url: entry.url
    };
  });
}

function showErrorResponse(errorResponse, clientError) {
  const errorResponseMessage = errorResponse?.ErrorObject?.PublicMessage || errorResponse.Message;
  if (clientError?.clientMessage) {
    notify.error(clientError.clientMessage, { autoGlossarize: true });
    return;
  }

  if (!errorResponse || !errorResponse.Message) {
    notify.error("An unexpected error occurred.");
    return;
  }

  if (errorResponse.IsInternal === false) {
    logger.error(errorResponseMessage);
    notify.error(errorResponseMessage);
  } else if (!windowonmessage && !windowonerror) {
    let message = errorResponse.Message;
    if (errorResponse.ErrorObject) {
      // more details are available if necessary
    }

    logger.error(message);

    if (errorResponse.ErrorDetailsUrl) {
      message += "<br /><a href='" + errorResponse.ErrorDetailsUrl + "'>View Details</a>";
    }

    // the "message" is the stack trace from the server.
    // do not attempt to glossarize it or an infinite loop of requests will ensue.
    notify.error(message, { autoGlossarize: false });
  } else {
    if (windowonerror.name === "ClientError") {
      windowonerror.getGlossedMessage().then((message) => {
        logger.error(message);
        notify.error(message);
      });
    } else {
      logger.error(windowonerror);
      notify.error(windowonerror);
    }

    windowonmessage = null;
  }
}

function isSameAsLastError(errorInfo) {
  const last = lastError;
  lastError = errorInfo;

  if (!errorInfo || !last) {
    return false;
  }

  if (errorInfo === last) {
    return true;
  }

  return errorInfo.name === last.name && errorInfo.message === last.message;
}

function recordError(errorInfo, showError) {
  if (!enabled || isSameAsLastError(errorInfo)) {
    return;
  }

  errorInfo.message = stringUtils.escapeHtml(errorInfo.message);

  try {
    errorInfo.pageState = getPageState();
  } catch (err) {
    // don't let retrieving state prevent error from being logged
  }

  try {
    normalizeErrorStack(errorInfo);
  } catch (err) {
    // ignore
  }

  try {
    $.ajax({
      url: nav.buildUrl("/error/logtracekit"),
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify(errorInfo)
    }).then((response) => showError && showErrorResponse(response, errorInfo));
  } catch (err) {
    // ignore error
  }
}

function raiseError(errOrMessage, logError) {
  let err = errOrMessage;
  if (typeof err === "string") {
    err = Error(err);
  }

  if (logError) {
    notify.error(err.message, { autoGlossarize: true });
  } else if (TraceKit) {
    const errorInfo = TraceKit.computeStackTrace(err);
    errorInfo.clientMessage = err.message;
    recordError(errorInfo, true);
  }
}

if (TraceKit) {
  TraceKit.remoteFetching = false;
  window.onerror = function (message, source, line, col, error) {
    windowonmessage = message;
    windowonerror = error;
  };
  TraceKit.report.subscribe((err) => recordError(err, true));

  window.addEventListener("unhandledrejection", (e) => {
    if (e.reason) {
      const errorInfo = TraceKit.computeStackTrace(e.reason);

      // We are not showing error because these were not previously captured
      // and may end up displaying errors to users that could safely be ignored.
      // We do want to capture these though. If these unhandled errors are
      // eventually cleaned up we could show these to the user as well.
      recordError(errorInfo, false);
    }
  });
}

$.Deferred.exceptionHook = function (error, stack) {
  if (error && error instanceof Error) {
    error.stack = error.stack || stack;
    raiseError(error, true);
  }
};

const api = {
  enable: function () {
    enabled = true;
    lastError = null;
  },

  disable: function () {
    enabled = false;
    lastError = null;
  },

  showErrorResponse,

  raise: raiseError
};

module.exports = api;
plexExport("ErrorHandler", api);
