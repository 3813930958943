/* eslint-disable no-invalid-this */
const { LocalDateTime } = require("js-joda");
const dateUtils = require("../Core/plex-dates");
const plexImport = require("../../global-import");
const plexExport = require("../../global-export");

const EST_OFFSET = -5;
let CUSTOMER_TZ = null;

function getTimeZone() {
  if (!CUSTOMER_TZ) {
    // lazy load customer TZ from client state and cache
    const tz = plexImport("appState").customer.globalizationInfo.timeZone;
    const offsets = plexImport("daylightSavingsInfo");
    CUSTOMER_TZ = new TimeZoneInfo(tz, offsets);
  }

  return CUSTOMER_TZ;
}

function getTimeZoneInfo(tz, offsets) {
  return new TimeZoneInfo(tz, offsets);
}

function getCustomerTimezoneOffset() {
  const tz = getTimeZone();
  return -tz.getOffset(...arguments);
}

function getOffset(date, rules) {
  const time = typeof date === "number" ? date : date.getTime();
  return rules.reduce((value, rule) => {
    return rule.start.getTime() <= time && rule.end.getTime() > time ? value - rule.offset : value;
  }, 0);
}

function createDSTMap(dstOffsets, offsetFromEST) {
  const props = ["fallDSTOffset", "springDSTOffset", "summerDSTOffset"];
  const utcOffset = offsetFromEST + EST_OFFSET;
  const map = { utc: {}, customer: {} };

  // gets the "base" offsets, ie Eastern offsets
  Object.keys(dstOffsets.utc).forEach((year) => {
    const rules = (map.utc[year] = []);

    props.forEach((offsetProperty) => {
      if (dstOffsets.utc[year][offsetProperty]) {
        rules.push(
          new TimeZoneOffset(
            dstOffsets.utc[year][offsetProperty + "BeginDate"],
            dstOffsets.utc[year][offsetProperty + "EndDate"],
            dstOffsets.utc[year][offsetProperty],
            true
          )
        );
      }
    });
  });

  // now apply customer's offsets against Eastern's DST range
  Object.keys(dstOffsets.customer).forEach((year) => {
    const rules = map.utc[year];

    props.forEach((offsetProperty) => {
      if (dstOffsets.customer[year][offsetProperty]) {
        let start = new Date(dstOffsets.customer[year][offsetProperty + "BeginDate"]);

        if (
          offsetProperty === "springDSTOffset" &&
          start &&
          dstOffsets.customer[year][offsetProperty + "BeginDate"].startsWith(year + "-01-01T") &&
          (dstOffsets.customer[Number(year) - 1]?.summerDSTOffsetEndDate || "").startsWith(year + "-01-01T")
        ) {
          // In the case of New Zealand, which begins its year in DST, we need to make sure we start the
          // customer's offsets there, not at the start of the Clarkston day
          start =
            Date.UTC(Number(year), 0, 1) - (utcOffset - dstOffsets.customer[year][offsetProperty]) * dateUtils.HOUR_MS;
        }

        const end = new Date(dstOffsets.customer[year][offsetProperty + "EndDate"]);
        rules.push(new TimeZoneOffset(start, end, -dstOffsets.customer[year][offsetProperty], true));
      }
    });
  });

  // now build up the valid ranges using customer dates
  // this will be used to determine offsets and validate customer specified date values

  Object.keys(map.utc).forEach((year) => {
    const offsets = map.utc[year];
    const rules = [];
    let current;

    let startingOffset = utcOffset * dateUtils.HOUR_MS;

    // determine the offset at the beginning of the year (which is usually, but not always, the standard offset)
    if (
      (dstOffsets.customer[year]?.springDSTOffsetBeginDate || "").startsWith(year + "-01-01T") &&
      (dstOffsets.customer[Number(year) - 1]?.summerDSTOffsetEndDate || "").startsWith(year + "-01-01T")
    ) {
      startingOffset += dstOffsets.customer[year].springDSTOffset * dateUtils.HOUR_MS;

      offsets.push(
        new TimeZoneOffset(
          Date.UTC(Number(year), 0, 1) - startingOffset,
          Math.min(...offsets.map((x) => x.start.getTime())) - 1,
          -1 * dstOffsets.customer[year].springDSTOffset,
          true
        )
      );
    }

    let endingOffset = utcOffset * dateUtils.HOUR_MS;
    // determines the offset at the end of the year (which is usually, but not always, the standard offset)
    if ((dstOffsets.customer[Number(year)]?.summerDSTOffsetEndDate || "").startsWith(Number(year) + 1 + "-01-01T")) {
      endingOffset += dstOffsets.customer[year].summerDSTOffset * dateUtils.HOUR_MS;

      offsets.push(
        new TimeZoneOffset(
          Math.max(...offsets.map((x) => x.end.getTime())),
          Date.UTC(Number(year) + 1, 0, 1) + endingOffset,
          -1 * dstOffsets.customer[year].summerDSTOffset,
          true
        )
      );
    }

    // add begin/end dates for the year
    const dates = [Date.UTC(Number(year), 0, 1) - startingOffset, Date.UTC(Number(year) + 1, 0, 1) - endingOffset]
      .concat(
        ...offsets.map((r) =>
          // flatten start/end dates dates - append outside range so all valid times get picked up in the case of overlapping ranges
          [r.start.getTime(), r.end.getTime()]
        )
      )
      // get offset for each date
      .map((time) => {
        return {
          timestamp: time,
          offset: utcOffset + getOffset(time, offsets)
        };
      })
      // sort by date
      .sort((a, b) => {
        return a.timestamp - b.timestamp;
      });

    // this may have duplicate dates because of overlapping ranges, but the overhead will be small
    dates.forEach((dt) => {
      current = current || dt;

      if (current.offset !== dt.offset) {
        rules.push(new TimeZoneOffset(current.timestamp, dt.timestamp - 1, current.offset));
        current = dt;
      }
    });

    // make sure last range gets added
    if (rules.length === 0 || rules[rules.length - 1].offset !== current.offset) {
      current = current || dates[0];
      rules.push(new TimeZoneOffset(current.timestamp, dates[dates.length - 1].timestamp, current.offset));
    }

    map.customer[year] = rules;
  });

  return map;
}

const localTimeFromDateArgs = (args) => {
  if (args.length === 1) {
    const dt = args[0] instanceof Date ? args[0] : new Date(args[0]);
    return localTimeFromDateArgs([
      dt.getUTCFullYear(),
      dt.getUTCMonth(),
      dt.getUTCDate(),
      dt.getUTCHours(),
      dt.getUTCMinutes(),
      dt.getUTCSeconds(),
      dt.getUTCMilliseconds()
    ]);
  }

  return LocalDateTime.of(
    args[0], // year
    // js-joda uses 1 based month
    args[1] >= 0 ? args[1] + 1 : args[1], // month
    args[2], // date
    args[3], // hour
    args[4], // minute
    args[5], // second
    // js-joda uses nanoseconds
    args[6] ? args[6] * 1_000_000 : args[6] // millisecond
  );
};

const inLocalRange = (start, end, dt) => {
  if (start.isBefore(dt) && end.isAfter(dt)) {
    return true;
  }

  // use inclusive start, exclusive end
  return start.isEqual(dt);
};

const adjustDateToLocal = (dt, offset) => {
  return localTimeFromDateArgs([dt]).plusHours(offset);
};

function TimeZoneInfo(tz, dstOffsets) {
  this.utcOffset = tz.timeZoneOffset + EST_OFFSET;
  this.dstMap = createDSTMap(dstOffsets, tz.timeZoneOffset);
}

TimeZoneInfo.prototype = {
  constructor: TimeZoneInfo,

  getOffset: function () {
    let offset = this.utcOffset;

    if (arguments.length < 2) {
      // this could be a date, string, number - let Date constructor throw if invalid
      const dt = new Date(...arguments);
      const utc = this.dstMap.utc[dt.getUTCFullYear()];

      // adjust for all offsets associated with the given date
      if (utc?.length > 0) {
        offset += getOffset(dt, utc);
      }

      return offset * 60;
    }

    // this will be in customer time
    const logicalDate = localTimeFromDateArgs(arguments);
    const rules = this.dstMap.customer[logicalDate.year()];

    if (rules?.length > 0) {
      let i = rules.length;
      while (i--) {
        const { startLogical, endLogical, offset: ruleOffset } = rules[i];

        if (inLocalRange(startLogical, endLogical, logicalDate)) {
          return ruleOffset * 60;
        }

        // be forgiving about logical dates within invalid DST ranges
        const previousLogicalEnd = rules[i - 1]?.endLogical;
        if (previousLogicalEnd && logicalDate.isAfter(previousLogicalEnd)) {
          return ruleOffset * 60;
        }
      }

      throw new Error("Invalid Logical Date: " + JSON.stringify([...arguments]));
    }

    return offset * 60;
  },

  isValidDate: function () {
    if (arguments.length === 0) {
      return true;
    }

    let logicalDate;

    try {
      if (arguments.length === 1) {
        logicalDate = localTimeFromDateArgs([new Date(arguments[0])]);
      } else {
        logicalDate = localTimeFromDateArgs(arguments);
      }
    } catch {
      return false;
    }

    const rules = this.dstMap.customer[logicalDate.year()];

    if (rules?.length > 0) {
      let i = rules.length;
      while (i--) {
        const { startLogical, endLogical } = rules[i];
        if (inLocalRange(startLogical, endLogical, logicalDate)) {
          return true;
        }

        const previousLogicalEnd = rules[i - 1]?.endLogical;
        if (previousLogicalEnd && logicalDate.isAfter(previousLogicalEnd)) {
          return false;
        }
      }

      return false;
    }

    // this would indicate a date without metadata available
    // since we can't validate it, allow it
    return true;
  }
};

function TimeZoneOffset(startTime, endTime, offsetHours, adjustEndTime = false) {
  this.start = new Date(startTime);
  this.startLogical = adjustDateToLocal(this.start, offsetHours);

  this.end = new Date(endTime);

  if (adjustEndTime) {
    this.end = new Date(this.end.getTime() + (1000 - this.end.getMilliseconds()));
  }

  this.endLogical = adjustDateToLocal(this.end, offsetHours);
  this.offset = offsetHours;
}

function LogicalDate(year, month, date, hour, minute, second, millisecond, day) {
  this.year = year;
  this.month = month || 0;
  this.date = date || 1;
  this.hour = hour || 0;
  this.minute = minute || 0;
  this.second = second || 0;
  this.millisecond = millisecond || 0;
  this.day = day || 0;
}

LogicalDate.fromTime = function (timestamp) {
  const logicalDateUtc = new Date(timestamp);

  return new LogicalDate(
    logicalDateUtc.getUTCFullYear(),
    logicalDateUtc.getUTCMonth(),
    logicalDateUtc.getUTCDate(),
    logicalDateUtc.getUTCHours(),
    logicalDateUtc.getUTCMinutes(),
    logicalDateUtc.getUTCSeconds(),
    logicalDateUtc.getUTCMilliseconds(),
    logicalDateUtc.getUTCDay()
  );
};

LogicalDate.fromDate = function (date) {
  const tz = getTimeZone();
  if (date instanceof Date) {
    return LogicalDate.fromTime(date.getTime() + tz.getOffset(date) * dateUtils.MINUTE_MS);
  }

  if (date instanceof LogicalDate) {
    return LogicalDate.fromDate(...date.getDateArgs());
  }

  let timestamp = Date.UTC(...arguments);
  if (!tz.isValidDate(timestamp)) {
    // try to adjust once to account for DST issues
    timestamp += dateUtils.HOUR_MS;
    if (!tz.isValidDate(timestamp)) {
      throw new Error("Invalid Date Arguments.");
    }
  }

  return LogicalDate.fromTime(timestamp);
};

LogicalDate.prototype = {
  constructor: LogicalDate,

  compare: function (other) {
    if (!other) {
      return 1;
    }

    const args = ["year", "month", "date", "hour", "minute", "second", "millisecond"];
    let i = 0;
    let arg;

    while (i < 7) {
      arg = args[i++];
      if (this[arg] !== other[arg]) {
        return this[arg] > other[arg] ? 1 : -1;
      }
    }

    return 0;
  },

  getDateArgs: function () {
    return [this.year, this.month, this.date, this.hour, this.minute, this.second, this.millisecond];
  },

  toDate: function () {
    // get UTC offset for given logical date
    const tz = getTimeZone();
    const args = this.getDateArgs();
    const offset = tz.getOffset(...args);

    // create a timestamp from UTC, which will handle overflows
    const time = Date.UTC(...args);

    // adjust the time using the offset
    return new Date(time - offset * dateUtils.MINUTE_MS);
  }
};

if (Object.defineProperty) {
  for (const prop in LogicalDate.prototype) {
    if (Object.prototype.hasOwnProperty.call(LogicalDate.prototype, prop)) {
      Object.defineProperty(LogicalDate.prototype, prop, { enumerable: false });
    }
  }
}

module.exports = {
  LogicalDate,
  getTimeZoneInfo,
  getCustomerTimezoneOffset
};

plexExport("dates.getTimeZoneInfo", getTimeZoneInfo);
plexExport("dates.getCustomerTimezoneOffset", getCustomerTimezoneOffset);
