const $ = require("jquery");
const ko = require("knockout");
const env = require("@plex/ux/Core/plex-parsing-url");
const DocumentGalleryItem = require("./plex-document-gallery-item-base");
const plexExport = require("../../../global-export");
const envisionPath = require("../../../../plex-envision/envision-path");

const DocumentGalleryItemEnvision = DocumentGalleryItem.extend({
  onInit: function () {
    this.displayExpand(false);
    this.displayDownload(false);
    this.isEnvision(true);

    this.config.height = "100%";
    this.config.width = "100%";

    const root = env.getStaticDomainRoot();

    /* eslint-disable prettier/prettier */
    this.canvasSrc =
      '<html style="height: 100%; width: 100%;">' +
        '<body style="height: 100%; width: 100%;">' +
          '<ev-viewer style="height: 100%; width: 100%;"' +
            'wasm_path="' + this.config.canvasWorkspace.assetPath + '"' +
            'workspace="' + this.config.canvasWorkspace.workspaceId + '"' +
            'center="' + this.config.canvasWorkspace.centerId + '"' +
            'bearer="' + this.config.canvasWorkspace.bearerToken + '"' +
            'id = "' + this.config.documentId.replace(/\//g, "") + '">' +
          '</ev-viewer>' +
          '<link rel="stylesheet" href="' + root + envisionPath + 'plex-envision.css" />' +
          '<script crossorigin="anonymous" type="text/javascript" src="' + root + envisionPath + 'plex-envision.js"></script>' +
        '</body>' +
      '</html>';
  },

  getXml: function () {
    const canvasNode = $("<ev-viewer/>");

    if (this.config.canvasWorkspace) {
      canvasNode.attr("wasm_path", this.config.canvasWorkspace.assetPath);
      canvasNode.attr("workspace", this.config.canvasWorkspace.workspaceId);
      canvasNode.attr("center", this.config.canvasWorkspace.centerId);
      canvasNode.attr("bearer", this.config.canvasWorkspace.bearerToken);
      canvasNode.attr("id", this.documentId);
    }

    canvasNode.attr({
      height: ko.unwrap(this.height()),
      width: ko.unwrap(this.width())
    });

    return canvasNode;
  }
});

module.exports = DocumentGalleryItemEnvision;
plexExport("documentGallery.DocumentGalleryItemEnvision", DocumentGalleryItemEnvision);
